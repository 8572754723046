import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import loadinggifts from "images/loading-gifts.gif";
import { SectionHeading, Subheading as SubheadingBase } from "./Headings.js";

const Container = tw.div`relative flex justify-center items-center h-screen flex-col`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 `;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);

const Subheading = tw(
  SubheadingBase
)`mt-3 text-4xl sm:text-5xl lg:text-5xl text-center leading-tight`;

/** Loading message used by components that fetch API data. */

function LoadingSpinner() {
  return (
    <Container>
      <Subheading>Loading...</Subheading>
      <img src={loadinggifts} alt="Loading Gifts"></img>
    </Container>
  );
}

export default LoadingSpinner;
