import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import DesignIllustration from "../../images/design-illustration-2.svg";
import DesignIllustration from "../../images/mainpage1.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-16 md:py-20`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container>
        <TwoColumn>

          <LeftColumn>
            <Heading>
              Welcome to {" "}
              <span tw="text-primary-500">Giftify!</span>
            </Heading>
            <Paragraph>Your go-to destination for finding the perfect gift! We know how stressful it can be 
              to find a gift that your loved one will truly appreciate, so we've created a user-friendly 
              platform to help take the guesswork out of gift-giving. 
              </Paragraph>
              <PrimaryButton buttonRounded={true} as="a" href="/giftideas">
              Explore Gift Ideas
            </PrimaryButton>
          </LeftColumn>

          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>

        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
