import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import FeedbackButtons from "components/misc/FeedbackButtons";
import AmazonAds from "./Amazon";
import Social from "./Social";
import { useState } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/giftideas.png";
import AnimationRevealPage from "helpers/AnimationRevealPage";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-4 md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat h-full max-w-[60%] relative`,
  `top: 2%;`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const ListItem = tw.li`my-2 py-2 px-2 font-bold border-2 border-primary-200`;
const ShopButton = tw.button`bg-primary-500 hover:bg-primary-500 text-white font-bold py-1 px-3 rounded`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight py-8`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Label = tw.label`mx-2 mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;
const Button = tw(PrimaryButtonBase)`inline-block my-2`;

export default ({
  subheading = "",
  heading = (
    <>
      Here are some <span tw="text-primary-500">Gift Options!</span>
      <wbr />
    </>
  ),
  submitButtonText = "New Gift Ideas",
  textOnLeft = true,
  gifts,
  goBack,
  sendLike,
  errorParagraph,
}) => {
  const handleGoBack = () => {
    goBack();
  };

  // console.log(requestId);
  // parsing JSON
  gifts = JSON.parse(gifts);

  return (
    <AnimationRevealPage>
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={EmailIllustrationSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {/* {subheading && <Subheading>{subheading}</Subheading>} */}
              <Heading>{heading}</Heading>
              {errorParagraph ? (
                <>
                  <Description>
                    Unfortunately our recommendation engine is down. Here are
                    some generic gift ideas for you!
                  </Description>
                  <br></br>
                </>
              ) : (
                <span></span>
              )}

              <div className="gifts">
                {/* <p>{searched && gifts.length > 0 ? gifts : null}</p> */}
                <ul>
                  {gifts.map((gift, idx) => (
                    <ListItem key={idx + 1}>
                      {idx + 1}. {gift}
                      <a
                        target="_blank"
                        href={`https://www.amazon.com/gp/search?ie=UTF8&tag=noid074-20&linkCode=ur2&linkId=58c7343108f084fa88617fa4992b65e3&camp=1789&creative=9325&index=aps&keywords=${gift}`}
                      >
                        &emsp;
                        <ShopButton className="amzn-shop-btn">
                          Shop All
                        </ShopButton>
                      </a>
                      <br></br>
                      <br></br>
                      <AmazonAds
                        key={idx * 100}
                        gift={gift}
                        id={idx}
                      ></AmazonAds>
                    </ListItem>
                  ))}
                </ul>
                <FeedbackButtons sendLike={sendLike}></FeedbackButtons>
                <Button
                  onClick={() => handleGoBack()}
                  className="btn btn-md btn-success"
                >
                  {submitButtonText}
                </Button>
                <Description>"Tweet it, Post it, Share it!"</Description>
                <Social></Social>
              </div>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </AnimationRevealPage>
  );
};
