import React, { useEffect, useRef } from "react";
import postscribe from "postscribe";

const AmazonAds = ({ gift, id }) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    const scriptContent = `
    amzn_assoc_placement = "adunit0";
    amzn_assoc_search_bar = "false";
    amzn_assoc_tracking_id = "noid074-20";
    amzn_assoc_rows = "1";
    amzn_assoc_ad_mode = "search";
    amzn_assoc_ad_type = "smart";
    amzn_assoc_marketplace = "amazon";
    amzn_assoc_region = "US";
    amzn_assoc_title = "";
    amzn_assoc_default_search_phrase = "${gift}";
    amzn_assoc_default_category = "All";
    amzn_assoc_linkid = "7abeb69e70156083cb925d1c365c9839";
    `;

    const amazonAdsScriptUrl =
      "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US";

    postscribe(
      scriptRef.current,
      `<script id="script21" type="text/javascript">${scriptContent}</script>
      <script id="script2" src="${amazonAdsScriptUrl}"></script>`,
      {
        done: () => {
          console.log("Amazon Ads scripts loaded and executed successfully");
        },
        error: (error) => {
          console.error("Error loading Amazon Ads scripts:", error);
        },
      }
    );

    return () => {
      // Clean up the added script element
      try {
        const divElement = document.getElementById(`${id}`);

        while (divElement.firstChild) {
          divElement.removeChild(divElement.firstChild);
        }
      } catch {}
    };
  }, []);

  return <div id={id} ref={scriptRef} />;
};

export default AmazonAds;
