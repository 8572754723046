import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/mainpage3.png";

import macHeroScreenshotImageSrc from "images/mainpage4.png";

import Header from "components/headers/light.js";

import SupportIconImage from "images/support-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={true} />
      <Hero />

      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to find the <HighlightedText>Perfect Gift</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />

      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Why use <HighlightedText>Giftify?</HighlightedText>
          </>
        }
        description="Why waste time and energy scouring the internet for the perfect
        gift when you can let Giftify do the work for you? With our expert
        guidance and personalized recommendations, you can be sure that your
        gift will be a gratuity that truly shows how much you care."
        cards={[
          {
            imageSrc: FastIconImage,
            title: "Fast",
            description:
              "See the best gift options including links to purchase them just in seconds! ",
          },
          {
            imageSrc: SimpleIconImage,
            title: "Easy",
            description:
              "Our gift recommendation tool is easy to use and self-explanatory.",
          },
          {
            imageSrc: SupportIconImage,
            title: "AI Supported",
            description:
              "AI analyzes your inputs to find the perfect gift options for your loved ones.",
          },
        ]}
      />

      <MainFeature
        subheading={<Subheading>Details</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={false}
        imageDecoratorBlob={true}
      />

      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Users <HighlightedText>Love Giftify.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Gift finding got much easier.",
            quote:
              "I was completely stuck on what to get my best friend for her birthday, but after answering a few questions on the website, I was presented with the perfect gift idea. The best part? My friend absolutely loved it! I would highly recommend Giftify to anyone who's struggling to find the perfect gift.",
            customerName: "Charlotte Hale",
            customerTitle: "",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Always finds me the perfect gift!",
            quote:
              "I'm someone who always wants to give the perfect gift, but I'm not always sure what that is. Giftify has been a game-changer for me. The recommendations are so thoughtful and personalized, and I've gotten so many compliments on the gifts I've given since using the site. Thank you, Giftify!",
            customerName: "Adam Cuppy",
            customerTitle: "",
          },
        ]}
      />

      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question:
              "Do I need to pay or give my credit card info to get gift ideas?",
            answer: "No, our gift tool is completely free!",
          },
          {
            question:
              "How long does it take for me to get gift recommendations?",
            answer:
              "It takes only couple seconds to fill out the questionaire. You can get recommendations as soon as 20 seconds.",
          },
          {
            question: "How does gift recommendation work ?",
            answer: "Our tool works with AI to recommend you the best gifts.",
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
