import React from "react";
import tw from "twin.macro";

const Container = tw.div`relative my-4`;
const Atag = tw.a`relative mx-2`;

const Social = () => {
  const text = "Check out this amazing website for great gift ideas: ";

  return (
    <Container
      className="heateor_sss_sharing_container heateor_sss_horizontal_sharing"
      data-heateor-ss-offset="0"
      data-heateor-sss-href="https://gift-ify.com/"
    >
      <Container className="heateor_sss_sharing_ul">
        <Atag
          className="heateor_sss_facebook"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fgift-ify.com"
          title="Facebook"
          rel="nofollow noopener"
          target="_blank"
          style={{
            fontSize: "32px !important",
            boxShadow: "none",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <span
            className="heateor_sss_svg"
            style={{
              backgroundColor: "#3c589a",
              width: "35px",
              height: "35px",
              borderRadius: "999px",
              display: "inline-block",
              opacity: 1,
              float: "left",
              fontSize: "32px",
              boxSizing: "content-box",
              display: "inline-block",
              fontSize: "16px",
              padding: 0,
              verticalAlign: "middle",
              backgroundRepeat: "repeat",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <svg
              style={{ display: "block", borderRadius: "999px" }}
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="-5 -5 42 42"
            >
              <path
                d="M17.78 27.5V17.008h3.522l.527-4.09h-4.05v-2.61c0-1.182.33-1.99 2.023-1.99h2.166V4.66c-.375-.05-1.66-.16-3.155-.16-3.123 0-5.26 1.905-5.26 5.405v3.016h-3.53v4.09h3.53V27.5h4.223z"
                fill="#fff"
              ></path>
            </svg>
          </span>
        </Atag>
        <Atag
          className="heateor_sss_button_twitter"
          href="https://twitter.com/intent/tweet?url=https://gift-ify.com/&text=Check out this amazing website for great gift ideas!"
          title="Twitter"
          rel="nofollow noopener"
          target="_blank"
          style={{
            fontSize: "32px !important",
            boxShadow: "none",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <span
            className="heateor_sss_svg heateor_sss_s__default heateor_sss_s_twitter"
            style={{
              backgroundColor: "#55acee",
              width: "35px",
              height: "35px",
              borderRadius: "999px",
              display: "inline-block",
              opacity: 1,
              float: "left",
              fontSize: "32px",
              boxSizing: "content-box",
              display: "inline-block",
              fontSize: "16px",
              padding: 0,
              verticalAlign: "middle",
              backgroundRepeat: "repeat",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <svg
              style={{ display: "block", borderRadius: "999px" }}
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="-4 -4 39 39"
            >
              <path
                d="M28 8.557a9.913 9.913 0 0 1-2.828.775 4.93 4.93 0 0 0 2.166-2.725 9.738 9.738 0 0 1-3.13 1.194 4.92 4.92 0 0 0-3.593-1.55 4.924 4.924 0 0 0-4.794 6.049c-4.09-.21-7.72-2.17-10.15-5.15a4.942 4.942 0 0 0-.665 2.477c0 1.71.87 3.214 2.19 4.1a4.968 4.968 0 0 1-2.23-.616v.06c0 2.39 1.7 4.38 3.952 4.83-.414.115-.85.174-1.297.174-.318 0-.626-.03-.928-.086a4.935 4.935 0 0 0 4.6 3.42 9.893 9.893 0 0 1-6.114 2.107c-.398 0-.79-.023-1.175-.068a13.953 13.953 0 0 0 7.55 2.213c9.056 0 14.01-7.507 14.01-14.013 0-.213-.005-.426-.015-.637.96-.695 1.795-1.56 2.455-2.55z"
                fill="#fff"
              ></path>
            </svg>
          </span>
        </Atag>
        <Atag
          className="heateor_sss_button_reddit"
          href="https://reddit.com/submit?&title=Check out this amazing website for great gift ideas!&url=https://gift-ify.com"
          title="Reddit"
          rel="nofollow noopener"
          target="_blank"
          style={{
            fontSize: "32px !important",
            boxShadow: "none",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <span
            className="heateor_sss_svg heateor_sss_s__default heateor_sss_s_reddit"
            style={{
              backgroundColor: "#ff5700",
              width: "35px",
              height: "35px",
              borderRadius: "999px",
              display: "inline-block",
              opacity: 1,
              float: "left",
              fontSize: "32px",
              boxSizing: "content-box",
              display: "inline-block",
              fontSize: "16px",
              padding: 0,
              verticalAlign: "middle",
              backgroundRepeat: "repeat",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <svg
              style={{ display: "block", borderRadius: "999px" }}
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="-3.5 -3.5 39 39"
            >
              <path
                d="M28.543 15.774a2.953 2.953 0 0 0-2.951-2.949 2.882 2.882 0 0 0-1.9.713 14.075 14.075 0 0 0-6.85-2.044l1.38-4.349 3.768.884a2.452 2.452 0 1 0 .24-1.176l-4.274-1a.6.6 0 0 0-.709.4l-1.659 5.224a14.314 14.314 0 0 0-7.316 2.029 2.908 2.908 0 0 0-1.872-.681 2.942 2.942 0 0 0-1.618 5.4 5.109 5.109 0 0 0-.062.765c0 4.158 5.037 7.541 11.229 7.541s11.22-3.383 11.22-7.541a5.2 5.2 0 0 0-.053-.706 2.963 2.963 0 0 0 1.427-2.51zm-18.008 1.88a1.753 1.753 0 0 1 1.73-1.74 1.73 1.73 0 0 1 1.709 1.74 1.709 1.709 0 0 1-1.709 1.711 1.733 1.733 0 0 1-1.73-1.711zm9.565 4.968a5.573 5.573 0 0 1-4.081 1.272h-.032a5.576 5.576 0 0 1-4.087-1.272.6.6 0 0 1 .844-.854 4.5 4.5 0 0 0 3.238.927h.032a4.5 4.5 0 0 0 3.237-.927.6.6 0 1 1 .844.854zm-.331-3.256a1.726 1.726 0 1 1 1.709-1.712 1.717 1.717 0 0 1-1.712 1.712z"
                fill="#fff"
              ></path>
            </svg>
          </span>
        </Atag>
        <Atag
          className="heateor_sss_button_linkedin"
          href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fgift-ify.com&amp;title=Home"
          title="Linkedin"
          rel="nofollow noopener"
          target="_blank"
          style={{
            fontSize: "32px !important",
            boxShadow: "none",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <span
            className="heateor_sss_svg heateor_sss_s__default heateor_sss_s_linkedin"
            style={{
              backgroundColor: "#0077b5",
              width: "35px",
              height: "35px",
              borderRadius: "999px",
              display: "inline-block",
              opacity: 1,
              float: "left",
              fontSize: "32px",
              boxSizing: "content-box",
              display: "inline-block",
              fontSize: "16px",
              padding: 0,
              verticalAlign: "middle",
              backgroundRepeat: "repeat",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <svg
              style={{ display: "block", borderRadius: "999px" }}
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 32 32"
            >
              <path
                d="M6.227 12.61h4.19v13.48h-4.19V12.61zm2.095-6.7a2.43 2.43 0 0 1 0 4.86c-1.344 0-2.428-1.09-2.428-2.43s1.084-2.43 2.428-2.43m4.72 6.7h4.02v1.84h.058c.56-1.058 1.927-2.176 3.965-2.176 4.238 0 5.02 2.792 5.02 6.42v7.395h-4.183v-6.56c0-1.564-.03-3.574-2.178-3.574-2.18 0-2.514 1.7-2.514 3.46v6.668h-4.187V12.61z"
                fill="#fff"
              ></path>
            </svg>
          </span>
        </Atag>
        <Atag
          className="heateor_sss_whatsapp"
          href="https://api.whatsapp.com/send?text=Check out this amazing website for great gift ideas!%20https%3A%2F%2Fgift-ify.com"
          title="Whatsapp"
          rel="nofollow noopener"
          target="_blank"
          style={{
            fontSize: "32px !important",
            boxShadow: "none",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <span
            className="heateor_sss_svg"
            style={{
              backgroundColor: "#55eb4c",
              width: "35px",
              height: "35px",
              borderRadius: "999px",
              display: "inline-block",
              opacity: 1,
              float: "left",
              fontSize: "32px",
              boxSizing: "content-box",
              display: "inline-block",
              fontSize: "16px",
              padding: 0,
              verticalAlign: "middle",
              backgroundRepeat: "repeat",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <svg
              style={{ display: "block", borderRadius: "999px" }}
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="-6 -5 40 40"
            >
              <path
                className="heateor_sss_svg_stroke heateor_sss_no_fill"
                stroke="#fff"
                strokeWidth="2"
                fill="none"
                d="M 11.579798566743314 24.396926207859085 A 10 10 0 1 0 6.808479557110079 20.73576436351046"
              ></path>
              <path
                d="M 7 19 l -1 6 l 6 -1"
                className="heateor_sss_no_fill heateor_sss_svg_stroke"
                stroke="#fff"
                strokeWidth="2"
                fill="none"
              ></path>
              <path
                d="M 10 10 q -1 8 8 11 c 5 -1 0 -6 -1 -3 q -4 -3 -5 -5 c 4 -2 -1 -5 -1 -4"
                fill="#fff"
              ></path>
            </svg>
          </span>
        </Atag>
      </Container>
      <div className="heateorSssClear"></div>
    </Container>
  );
};

export default Social;
