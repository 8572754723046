import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

/** API Class.
 *
 * Static class tying together methods used to get/send to to the API.
 * There shouldn't be any frontend-specific stuff here, and there shouldn't
 * be any API-aware stuff elsewhere in the frontend.
 *
 */

class GiftifyApi {
  // the token for interactive with the API will be stored here.
  static token;

  static async request(endpoint, data = {}, method = "get") {
    // console.debug("API Call:", endpoint, data, method);

    const url = `${BASE_URL}/${endpoint}`;
    const headers = { Authorization: `Bearer ${GiftifyApi.token}` };
    const body = method === "post" ? data : {};

    try {
      return (await axios({ url, method, data, headers, body })).data;
    } catch (err) {
      // console.error("API Error:", err.response);
      let message = err.response.data.error.message;
      throw Array.isArray(message) ? message : [message];
    }
  }

  // Individual API routes

  /** Get the gift ideas. */
  static async getGiftIdeas(data) {
    let res = await this.request(`recommendationEngine`, data, "post");
    return res;
  }

  /** Create a request */
  static async createRequest(data) {
    let req = await this.request(`requests`, data, "post");
    return req;
  }

  /** Create a response */

  static async createResponse(gifts, letter, request) {
    let data = { gifts, letter, request };
    let response = await this.request(`responses`, data, "post");
    return response;
  }
  /** Create a like */

  static async createLike(data) {
    let response = await this.request(`likes`, data, "post");
    return response;
  }

  /** Get the current user. */

  static async getCurrentUser(username) {
    let res = await this.request(`users/${username}`);
    return res.user;
  }

  /** Get token for login from username, password. */

  static async login(data) {
    let res = await this.request(`auth/token`, data, "post");
    return res.token;
  }

  /** Signup for site. */

  static async signup(data) {
    let res = await this.request(`auth/register`, data, "post");
    return res.token;
  }

  /** Save user profile page. */

  static async saveProfile(username, data) {
    let res = await this.request(`users/${username}`, data, "patch");
    return res.user;
  }
}

export default GiftifyApi;
