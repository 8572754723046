import React, { useState, useEffect } from "react";
import GiftifyApi from "../api/api";
import LoadingSpinner from "components/misc/LoadingSpinner";
import defaultGifts from "components/misc/DefaultGifts";
import SearchGiftIdeas from "components/forms/SearchGiftIdeas";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Results from "./Results";

/** Show page with search gift ideas form.
 *
 * Re-loads with gift ideas results on submit from search form.
 *
 * This is routed to at /giftideas
 *
 */

function GiftIdeas() {
  const [gifts, setGifts] = useState([]);
  const [searched, setSearched] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [errorParagraph, seterrorParagraph] = useState(false);

  useEffect(() => {
    if (searched) {
      search();
    }
  }, []);

  /** Triggered by search form submit; retrieves results and insters data in the database . */
  async function search(data) {
    setSearched(true);

    try {
      // inserts a new request row in requests table
      const request = await GiftifyApi.createRequest(data);
      setRequestId(request.id);

      // calls for backend api to get gift ideas responses
      const gifts = await GiftifyApi.getGiftIdeas(data);

      // Handle letter
      const letter = "";

      // inserts a new response row in responses table
      const response = await GiftifyApi.createResponse(
        gifts,
        letter,
        request.id
      );

      setGifts(JSON.stringify(gifts));
    } catch (error) {
      console.debug(error);
      setGifts(JSON.stringify(defaultGifts));
      seterrorParagraph(true);
    }

    setHidden(!hidden);
    setSearched(false);
  }

  const goBack = () => {
    setHidden(!hidden);
    seterrorParagraph(false);
  };

  async function sendLike(feedback) {
    // inserts a new request row in requests table
    const request = await GiftifyApi.createLike({ feedback, requestId });
  }
  // returns a LoadingSpinner until the data is retrieved
  if (searched) return <LoadingSpinner />;

  // returns gift ideas search form or results based on state values
  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={true} />
      <div className="searchvsresult">
        {hidden ? (
          <Results
            sendLike={sendLike}
            search={searched}
            gifts={gifts}
            goBack={goBack}
            errorParagraph={errorParagraph}
          ></Results>
        ) : (
          <SearchGiftIdeas searchFor={search} />
        )}
      </div>
    </AnimationRevealPage>
  );
}

export default GiftIdeas;
