const gifts = [
  "A personalized photo album or scrapbook.",
  "A piece of jewelry such as a necklace, bracelet or earrings.",
  "A gourmet food basket filled with chocolates, cheeses, and other treats.",
  "A cozy blanket or throw pillow for their home.",
  "A smartwatch or fitness tracker.",
  "A stylish handbag or wallet.",
  "A high-quality set of headphones or earbuds.",
  "A fancy coffee or tea set.",
  "A professional-grade blender or kitchen appliance.",
  "A designer perfume or cologne.",
  "A subscription to their favorite magazine or online service.",
  "A beautiful vase or flower arrangement.",
  "A personalized stationery set or journal.",
  "A cookbook featuring their favorite cuisine.",
  "A stylish scarf or hat for the colder months.",
  "A high-end skincare or beauty product.",
  "A sleek phone case or laptop sleeve.",
  "A pair of comfortable yet fashionable shoes.",
  "A gourmet spice set for their kitchen.",
  "A set of elegant wine glasses or decanter.",
  "A gift card to their favorite store or restaurant.",
  "A luxurious bathrobe or pajama set.",
  "A beautiful piece of art for their home.",
  "A set of high-quality cookware or bakeware.",
  "A travel or hiking backpack.",
  "A personalized cutting board or kitchen gadget.",
  "A set of high-quality workout clothes or equipment.",
  "A smart home device like a voice assistant or smart thermostat.",
  "A personalized photo canvas or print.",
  "A luxurious spa gift set for pampering at home.",
  "A high-quality set of knives or kitchen utensils.",
  "A personalized puzzle featuring their favorite photo.",
  "A luxurious throw blanket for their living room.",
  "A subscription to a streaming service like Netflix or Hulu.",
  "A smart water bottle that tracks their hydration levels.",
  "A fun board game or puzzle.",
  "A set of luxurious bath towels or a bathrobe.",
  "A high-quality camera or photography accessory.",
  "A set of fine writing instruments or a fountain pen.",
  "A gourmet chocolate or coffee sampler.",
  "A set of elegant coasters or placemats.",
  "A personalized photo keychain or phone case.",
  "A set of high-quality bed sheets or a comforter.",
  "A fitness class or workout session package.",
  "A unique piece of home decor, such as a vase or sculpture.",
  "A set of artisanal or flavored salts for cooking.",
  "A portable Bluetooth speaker for music on the go.",
  "A stylish watch or piece of jewelry for men.",
  "A personalized canvas print of their pet or favorite animal.",
  "A unique experience, such as a hot air balloon ride or cooking class.",
];
const fixedGift = "A unique travel package";

function randomPicker(array) {
  let result = [];
  let currentIndex = array.length;
  let tempValue;
  let randomIndex;

  while (result.length < 9 && currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    tempValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = tempValue;

    result.push(tempValue);
  }

  return result;
}

let defaultGifts = randomPicker(gifts);
defaultGifts.push(fixedGift);

export default defaultGifts;
