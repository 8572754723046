import tw from "twin.macro";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as regularUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsDown as regularDown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { React, useState } from "react";

const Container = tw.div`flex my-4`;
const ThumbsUpButton = tw.button`text-green-700 hover:text-white hover:bg-green-200 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2  dark:text-green-500 dark:hover:text-white dark:hover:bg-green-200 dark:focus:ring-green-800`;
const ThumbsDownButton = tw.button`text-red-700 hover:text-white  hover:bg-red-200 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2  dark:text-red-500 dark:hover:text-white dark:hover:bg-red-200 dark:focus:ring-red-900`;
const ClickedThumbsUpButton = tw.button`text-green-700 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2  dark:text-green-500`;
const ClickedThumbsDownButton = tw.button`text-red-700 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2  dark:text-red-500`;
const Description = tw.p`mt-2 text-center md:text-left text-xs md:text-base lg:text-sm font-medium leading-relaxed text-secondary-100`;
library.add(fas);

function FeedbackButtons({ sendLike }) {
  const [hidden, setHidden] = useState(false);

  function handleClickTrue(evt) {
    evt.preventDefault();
    sendLike(true);
    setHidden(true);
  }
  function handleClickFalse(evt) {
    evt.preventDefault();
    sendLike(false);
    setHidden(true);
  }

  return (
    <Container>
      {hidden ? (
        <Description>Thank you for your feedback!</Description>
      ) : (
        <>
          <Description>Did you like the gift ideas?</Description>
          <ThumbsUpButton onClick={handleClickTrue}>
            <FontAwesomeIcon
              icon={regularUp}
              size="lg"
              style={{ color: "#3c9424" }}
            />
          </ThumbsUpButton>
          <ThumbsDownButton onClick={handleClickFalse}>
            <FontAwesomeIcon
              icon={regularDown}
              size="lg"
              style={{ color: "#c93636" }}
            />
          </ThumbsDownButton>
        </>
      )}
    </Container>
  );
}

export default FeedbackButtons;
