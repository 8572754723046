import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import posts from "pages/BlogPosts";
import AmazonAds from "demos/Amazon";

import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { useParams } from "react-router-dom";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const ShopButton = tw.button`bg-primary-500 hover:bg-primary-500 text-white font-bold py-1 px-3 rounded`;
const Post = tw.div`flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-primary-500`;
const Description = tw.div``;
const ListItem = tw.li`my-2 py-2 px-2 font-bold border-2 border-gray-300`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}

  ${tw`w-full!`}
      ${Post} {
    ${tw`sm:flex-row! h-full sm:pr-4`}
  }
  ${Image} {
    ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
  }
  ${Info} {
    ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
  }
  ${Description} {
    ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
  }
`;
export default () => {
  const { blogName } = useParams();
  const post = posts.find((post) => post.blogName === blogName);
  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={true} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{post.title}</Heading>
          </HeadingRow>

          <PostContainer>
            <ContentWithPaddingXl>
              <Image imageSrc={post.imageSrc} />
            </ContentWithPaddingXl>
            <Post className="group">
              <Info>
                <Category>{post.category}</Category>
                <CreationDate>{post.date}</CreationDate>
                {/* <Title>{post.title}</Title> */}
                <Description>{post.p1}</Description>

                <Description>{post.p2}</Description>
                <Title>30 Gift Ideas</Title>
                <Description>
                  <ul>
                    {post.items.map((item, idx) => (
                      <ListItem key={idx + 1}>
                        {idx + 1}. {item}
                        <a
                          target="_blank"
                          href={`https://www.amazon.com/gp/search?ie=UTF8&tag=noid074-20&linkCode=ur2&linkId=58c7343108f084fa88617fa4992b65e3&camp=1789&creative=9325&index=aps&keywords=${item
                            .match(/^([^-]+)/)[1]
                            .trim()}`}
                        >
                          &emsp;
                          <ShopButton>Shop All</ShopButton>
                        </a>
                        <br></br>
                        <br></br>
                        <AmazonAds
                          key={idx * 100}
                          gift={item.match(/^([^-]+)/)[1].trim()}
                          id={idx}
                        ></AmazonAds>
                      </ListItem>
                    ))}
                  </ul>
                </Description>
                <Description>{post.p3}</Description>
              </Info>
            </Post>
          </PostContainer>
        </ContentWithPaddingXl>
        <PrimaryButton buttonRounded={true} as="a" href="/blog">
          Go Back
        </PrimaryButton>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
