import FathersDay from "images/fathersday.jpg";
import Christmas from "images/christmas.jpg";
import Hims from "images/hims.jpeg";
import Hers from "images/hers.jpeg";
import MothersDay from "images/mothersday.jpeg";

const posts = [
  {
    blogName: "fathersday",
    imageSrc: FathersDay,
    category: "Gifting Tips",
    date: "April 21, 2022",
    title: "Best Gift For Your Dad",
    description: "Give the best gift for your dad for fathers day..",
    p1: `Father's Day is a special occasion to show our appreciation and love for the fathers in our lives. It's a time to honor the men who have been there for us, offering guidance and support when we needed it most. And what better way to show our gratitude than by buying them a gift that will make them feel special and appreciated? In this blog post, we'll explore some of the best gift ideas for Father's Day and how you can choose the perfect present for your dad.`,

    p2: `When it comes to buying a gift for your dad, it's essential to consider his personality, interests, and preferences. For example, if your dad is an avid golfer, you might want to consider buying him a new set of golf clubs. If he's a foodie, you could surprise him with a gourmet gift basket filled with his favorite snacks and treats. Whatever you choose, make sure it's something that reflects his personality and interests.
    Another important factor to consider is the budget. You don't need to break the bank to find a meaningful gift for your dad. Remember, it's the thought that counts. Even a small, thoughtful gift can make a big impact and show your dad how much you care.`,

    items: [
      "Personalized photo album - This is a great way to showcase some of your dad's favorite memories and moments with personalized captions or messages.",
      "Customized whiskey glasses - If your dad enjoys whiskey, he'll appreciate a set of glasses that are customized with his name or initials.",
      "A new grill or smoker - For dads who love to grill, a new grill or smoker can be the perfect gift to help them create even more delicious meals.",
      "A subscription to a favorite magazine or newspaper - Whether your dad is into sports, finance, or politics, a subscription to his favorite magazine or newspaper is a thoughtful gift that he'll enjoy all year long.",
      "A smartwatch or fitness tracker - This gift is perfect for dads who love to stay active and keep track of their fitness goals.",
      "A set of high quality headphones - For dads who love music or frequently listen to audiobooks or podcasts, a set of high quality headphones can make a big difference.",
      "A weekend getaway to a nearby city - If you want to give your dad the gift of experience, consider planning a weekend getaway to a nearby city that he's always wanted to visit.",
      "A set of personalized golf balls - This is a great gift for dads who love golf and will appreciate a set of golf balls that are customized with their name or initials.",
      "A gourmet coffee or tea gift set - For dads who love coffee or tea, a gourmet gift set is a great way to treat them to some of the best flavors and blends out there.",
      "A stylish leather men's wallet - A high quality leather wallet is a timeless gift that your dad can use every day.",
      "A set of grilling tools - This gift is perfect for dads who love to grill and need some new tools to create delicious meals.",
      "A new tool kit - For dads who are handy and love to fix things around the house, a new tool kit is a great way to give them the tools they need to get the job done.",
      "A home brewing kit - If your dad loves beer, a home brewing kit is a fun gift that allows him to create his own unique brews.",
      "A personalized wooden cutting board - A personalized cutting board is a thoughtful gift that your dad can use in the kitchen for years to come.",
      "A customized beer stein or mug - For dads who love beer, a customized beer stein or mug is a great way to show your appreciation and add a personal touch to his favorite beverage.",
      "A set of personalized coasters - This is a fun and practical gift that your dad can use to protect his furniture while enjoying his favorite drinks.",
      "A luxury shaving kit - If your dad takes pride in his appearance, a luxury shaving kit is a great way to help him elevate his grooming routine.",
      "A drone or remote controlled car - For dads who love tech and gadgets, a drone or remote controlled car is a fun and exciting gift that they can enjoy for hours.",
      "A classic watch - A classic watch is a timeless gift that your dad can wear every day and appreciate for years to come.",
      "A sports jersey or gear from their favorite team - If your dad is a sports fan, getting him a jersey or gear from his favorite team is a thoughtful and practical gift.",
      "A new set of luggage - For dads who love to travel, a new set of luggage is a great way to help them pack and organize their belongings for their next trip.",
      "A stylish messenger bag or backpack - A stylish and functional messenger bag or backpack is a practical gift that your dad can use for work or travel.",
      "A custom photo canvas - A custom photo canvas is a great way to turn a favorite family photo into a piece of art that your dad can proudly display in his home or office.",
      "A virtual cooking class - For dads who enjoy cooking, a virtual cooking class is a fun and educational gift that allows them to learn new recipes and techniques from the comfort of their own kitchen.",
      "A personalized engraved pen - If your dad is a writer or someone who values handwritten notes, a personalized engraved pen is a thoughtful gift that he can use for years to come.",
      "A set of high quality steak knives - For dads who love to cook and entertain, a set of high quality steak knives is a great gift that can be used to serve up delicious meals.",
      "A subscription to a streaming service - Whether your dad loves movies or TV shows, a subscription to a streaming service is a gift that keeps on giving and allows him to enjoy his favorite entertainment on demand.",
      "A personalized leather journal - A personalized leather journal is a great gift for dads who enjoy writing, journaling, or sketching.",
      "A fitness equipment set - For dads who love to work out at home, a fitness equipment set is a great way to give them the tools they need to stay active and healthy.",
      "A personalized bottle opener - If your dad enjoys a cold beer or soda, a personalized bottle opener is a practical and fun gift that he can use to crack open his favorite beverages.",
    ],

    p3: `
    Father's Day is an opportunity to express our gratitude and appreciation for the fathers in our lives. With the right gift, we can show them just how much we care. When selecting a gift, consider their personality, interests, and preferences. With our list of 30 Father's Day gift ideas, you'll be able to find the perfect present for your dad. Whatever you choose, make sure it comes from the heart and reflects your appreciation for all that he has done for you.
    `,
    url: "/blog/fathersday",
    featured: true,
  },

  {
    blogName: "mothersday",
    imageSrc: MothersDay,
    category: "Gifting Tips",
    date: "April 21, 2022",
    title: "Mother's Day Gifts",
    description: "Give the best gift for your mom for mothers day..",
    p1: `Mother's Day is an annual celebration dedicated to honoring the incredible women who have given us life, love, and support throughout the years. It's a time to show our appreciation and gratitude for everything our moms have done for us, and what better way to do that than with a thoughtful and heartfelt gift? Whether your mom is a fashionista, a foodie, or a fitness enthusiast, there are plenty of great gift options available that are sure to bring a smile to her face. In this blog post, we'll take a closer look at some of the best Mother's Day gift ideas and provide you with a list of 30 possible gifts that you can buy for your mom. So let's dive in and find the perfect gift to make this Mother's Day one to remember!`,

    items: [
      `Personalized jewelry - A piece of jewelry with your mom's name or initials on it will make her feel extra special.`,
      `A spa day - Treat your mom to a day of pampering and relaxation at a local spa.`,
      `Custom photo album - Gather some of your favorite family photos and create a custom photo album for your mom to cherish.`,
      `A subscription box - Sign your mom up for a monthly subscription box that delivers her favorite treats or beauty products.`,
      `A cozy robe - Give your mom the gift of comfort with a soft and luxurious robe.`,
      `A fitness tracker - Encourage your mom to stay healthy and active with a fitness tracker.`,
      `A smart speaker - Give your mom the gift of convenience with a smart speaker that can play music, set reminders, and more.`,
      `A high quality camera - Help your mom capture all of life's precious moments with a high quality camera.`,
      `A cooking class - If your mom loves to cook, sign her up for a cooking class to learn some new techniques.`,
      `A personalized cutting board - Add a personal touch to your mom's kitchen with a custom cutting board.`,
      `A cozy throw blanket - Give your mom a cozy blanket that she can snuggle up with while reading or watching TV.`,
      `A luxurious candle - Help your mom unwind with a luxurious candle that smells amazing.`,
      `A gardening kit - If your mom loves gardening, give her a kit with all the tools she needs to grow her favorite plants.`,
      `A high quality coffee maker - If your mom loves coffee, upgrade her coffee maker to one that makes the perfect cup every time.`,
      `A stylish handbag - Give your mom a fashionable handbag that she can use every day.`,
      `A subscription to a magazine - Sign your mom up for a subscription to her favorite magazine.`,
      `A new tablet - Help your mom stay connected with a new tablet that she can use to read books, watch movies, and browse the internet.`,
      `A personalized phone case - Add a personal touch to your mom's phone with a custom phone case.`,
      `A beautiful vase - Give your mom a beautiful vase that she can use to display her favorite flowers.`,
      `A cookbook - If your mom loves to cook, give her a cookbook from her favorite chef.`,
      `A comfortable womens slippers - Help your mom relax with a comfortable pair of slippers.`,
      `A massage pillow - Give your mom the gift of a massage without leaving home with a massage pillow.`,
      `A stylish womens watch - Give your mom a stylish watch that she can wear every day.`,
      `A new pair of headphones - Help your mom listen to her favorite music or podcasts with a new pair of headphones.`,
      `A beautiful piece of art - Add some beauty to your mom's home with a beautiful piece of art.`,
      `A fitness class membership - Sign your mom up for a fitness class membership to help her stay healthy and active.`,
      `A cookbook stand - If your mom loves to cook, give her a cookbook stand to help her keep her recipes organized.`,
      `A cozy pair of pajamas - Give your mom a comfortable pair of pajamas that she can wear to bed.`,
      `A wine subscription - If your mom loves wine, give her the gift of a monthly wine subscription that delivers new and exciting wines to her door.`,
      `A set of fancy tea cups - If your mom loves tea, give her a set of fancy tea cups that she can use for her daily cup of tea or for entertaining guests.`,
    ],

    p3: `No matter what your mom's interests or preferences may be, there's a Mother's Day gift out there that will make her feel loved and appreciated. Use this list of ideas as a starting point to find the perfect gift for your mom this year. And remember, the most important thing is to show your mom how much you care and appreciate all that she does for you. Happy Mother's Day!`,

    url: "/blog/mothersday",
  },
  {
    blogName: "christmas",
    imageSrc: Christmas,
    category: "Christmas",
    date: "April 21, 2022",
    title: "Christmas Gift Ideas",
    description: "Are you ready for Christmas?",
    p1: `Christmas is a special time of year, and with it comes the excitement of gift-giving. Whether you're looking for a gift for a family member, friend, or colleague, there are plenty of options to choose from. In this blog post, we'll explore some ideas for Christmas gifts and provide a list of 30 possible gifts that you can purchase this holiday season.
    When it comes to gift-giving, it's important to think about the person you're buying for. What are their interests? What do they enjoy doing in their free time? What are their favorite foods or drinks? By answering these questions, you can find the perfect gift that will make them feel special and appreciated.`,

    p2: `One popular gift idea for Christmas is a subscription box. Subscription boxes are a great way to give a gift that keeps on giving. There are subscription boxes available for a wide range of interests, from cooking and baking to beauty and skincare.
    Another great gift idea for Christmas is a personalized gift. This could be anything from a customized mug or piece of jewelry to a monogrammed tote bag or jacket. Personalized gifts show that you've put extra thought into the gift and that you truly care about the person receiving it. If you're looking for a gift that's both practical and thoughtful, consider giving a gift card. Gift cards are a great way to give someone the gift of choice, allowing them to pick out exactly what they want or need. Without further ado, here are 30 possible Christmas gifts that you can purchase this holiday season:`,

    items: [
      `Amazon Echo Dot - This smart speaker can play music, set alarms, control smart home devices, and answer questions using Amazon's virtual assistant, Alexa.`,
      `Air Fryer - An air fryer is a kitchen appliance that uses hot air to cook food, giving it a crispy texture without the added oil of traditional frying.`,
      `Instant Pot - This popular kitchen appliance is a pressure cooker that can also be used as a slow cooker, rice cooker, steamer, and more.`,
      `Meal delivery service - A subscription to a meal delivery service like HelloFresh or Blue Apron provides pre-measured ingredients and recipe cards for easy meal preparation.`,
      `Electric Wine Opener - This handy device makes opening wine bottles effortless with just the push of a button.`,
      `Personalized Cutting Board - A unique and thoughtful gift, a personalized cutting board can feature the recipient's name or initials engraved on the surface.`,
      `Streaming service subscription - A subscription to a streaming service like Netflix or Hulu allows the recipient to watch their favorite TV shows and movies on demand.`,
      `Fitbit - A Fitbit is a wearable fitness tracker that can monitor steps taken, distance traveled, calories burned, and more.`,
      `Weighted Blanket - This type of blanket is filled with weighted beads or pellets to provide a calming and relaxing effect, and is great for reducing anxiety and improving sleep quality.`,
      `Smartwatch - A smartwatch can provide a variety of features, including fitness tracking, notifications, GPS, and mobile payments.`,
      `Wireless Earbuds - These compact earbuds connect wirelessly to a phone or tablet, providing an easy and convenient way to listen to music or podcasts on the go.`,
      `Yoga Mat - A yoga mat provides a comfortable and stable surface for yoga or other fitness activities.`,
      `Personalized Photo Album - A photo album with personalized touches, like a custom cover or captioned pages, makes a special and unique gift for preserving memories.`,
      `DIY Candle Making Kit - This kit includes everything needed to make homemade candles, including wax, wicks, fragrance oils, and containers, and is a fun and creative gift for crafty individuals.`,
      `Board Game - A classic gift, board games provide hours of entertainment for friends and family.`,
      `Book club subscription - A book club subscription like Book of the Month provides a monthly selection of books chosen by a team of experts, and is a great gift for book lovers.`,
      `Instant Camera - An instant camera allows you to take and print photos on the spot, providing a fun and tangible way to capture memories.`,
      `Customized Tumbler - A personalized tumbler can feature the recipient's name or initials, and is a useful gift for keeping drinks hot or cold on the go.`,
      `Indoor Herb Garden Kit - This kit provides everything needed to grow fresh herbs indoors, and is a great gift for anyone who enjoys cooking with fresh ingredients.`,
      `DNA Ancestry Kit - A DNA ancestry kit allows the recipient to learn about their genetic heritage and ancestry through a simple saliva test.`,
      `Noise Cancelling Headphones - These headphones can block out ambient noise for better focus and concentration, or simply to enjoy music without distractions.`,
      `Kindle - A Kindle is an e-reader that can store thousands of books, making it a great gift for avid readers.`,
      `Record Player - A classic gift for music lovers, a record player can play vinyl records for a nostalgic listening experience.`,
      `Personalized Luggage Tag - A personalized luggage tag makes it easy to identify luggage when traveling, and can feature the recipient's name or contact information.`,
      `Customized Stationery Set - A stationery set with personalized enjoys sending handwritten notes or letters.`,
      ` Portable Phone Charger - A portable phone charger is a useful gift for anyone who needs to charge their phone on the go, and can be a lifesaver in emergency situations.`,
      `Memory Foam Pillow - A memory foam pillow can provide a comfortable and supportive sleeping surface, and is a great gift for anyone who values a good night's sleep.`,
      `Fitness Equipment - Whether it's a set of resistance bands, a set of dumbbells, or a yoga ball, fitness equipment is a great gift for anyone who enjoys working out at home.`,
      `Personalized Wall Art - A personalized piece of wall art, like a custom photo print or a framed quote, is a great way to add a personal touch to someone's home decor.`,
      `Personalized Wine Glasses - A set of personalized wine glasses makes a great gift for anyone who enjoys wine, and can feature the recipient's name or initials engraved on the glass.`,
    ],

    p3: `In conclusion, Christmas is a time of year where gift-giving is a tradition that many people look forward to. By taking the time to consider the person you're buying for and their interests, you can find the perfect gift that will make them feel special and appreciated. From subscription boxes to personalized gifts, there are plenty of options to choose from this holiday season. So start your Christmas shopping early, and spread some holiday cheer with a thoughtful and unique gift.
    `,
    url: "/blog/christmas",
  },
  {
    blogName: "bestgiftsforhim",
    imageSrc: Hims,
    category: "Gifting Tips",
    date: "April 21, 2022",
    title: `Best Gift For Him`,
    description: "Give the best gift for your dad for fathers day..",
    p1: `Are you struggling to find the perfect gift for the special man in your life? Whether it's your boyfriend, husband, or a male friend, choosing the right gift can be a daunting task. But worry not! In this post, we'll guide you on how to choose the best gifts for him.`,

    items: [
      `Men’s Personalized Leather Wallet - A stylish and practical gift that can be customized with his name or initials.`,
      `Apple AirPods - High-quality wireless earbuds that deliver exceptional sound quality and come with a charging case.`,
      `Customized Photo Book - A personalized photo album that captures your memories together.`,
      `Fitbit Fitness Tracker: A smart wearable device that tracks his daily activity, heart rate, and sleep quality.`,
      `Men’s Engraved Watch - A timeless and elegant accessory that can be engraved with a special message.`,
      `Coffee Grinder - A high-quality grinder that allows him to enjoy freshly ground coffee at home.`,
      `Wireless Charger - A convenient and fast way to charge his smartphone or other compatible devices.`,
      `Fitness Resistance Bands - A set of versatile bands that can be used for a variety of exercises and workouts.`,
      `Beer Making Kit - A fun and educational kit that allows him to brew his own beer at home.`,
      `Smart Water Bottle: A high-tech water bottle that tracks his daily water intake and reminds him to stay hydrated.`,
      `Virtual Reality Headset - A cutting-edge headset that lets him experience immersive virtual reality games and entertainment.`,
      `Beard Grooming Kit - A comprehensive set of tools and products to help him maintain a well-groomed beard.`,
      `Handheld Massager - A portable device that provides targeted relief to sore muscles and tension.`,
      `Weighted Blanket - A cozy and comforting blanket that can promote better sleep and relaxation.`,
      `Leather Messenger Bag - A stylish and functional bag that can hold his laptop and other essentials.`,
      `Cocktail Making Kit - A fun and educational kit that allows him to mix and create his own cocktails at home.`,
      `Portable Grill - A compact and portable grill that's perfect for outdoor cooking and camping.`,
      `Bluetooth Speaker: A high-quality and portable speaker that delivers powerful sound and connects wirelessly to his devices.`,
      `Yoga Mat - A high-quality mat that provides cushioning and support during yoga and other exercises.`,
      `Personalized Tumbler - A stylish and practical tumbler that can be customized with his name or initials.`,
      `Men’s Running Shoes - A high-performance pair of shoes that provide comfort and support for his runs.`,
      `Electric Shaver - A high-quality shaver that delivers a close and comfortable shave.`,
      `Personalized Keychain - A thoughtful and personalized accessory that he can carry with him every day.`,
      `Gourmet Coffee Subscription - A monthly subscription that delivers premium coffee beans and blends from around the world.`,
      `Instant Pot - A versatile and multi-functional kitchen appliance that can pressure cook, slow cook, and more.`,
      `Kindle E Reader - A high-tech e-reader that lets him read and access books, magazines, and newspapers on the go.`,
      `Wine Glasses Set - A beautiful set of wine glasses that can enhance the taste and enjoyment of his favorite wines.`,
      `Travel Backpack - A durable and functional backpack that's perfect for traveling and outdoor activities.`,
      `Customized Cutting Board - A personalized and practical cutting board that can be customized with his name or initials.`,
      `Golf Club Set - A comprehensive set of high-quality golf clubs that can improve his game and enjoyment on the course.`,
    ],

    p3: `There you have it - 30 gift ideas to help you choose the best gift for him. Remember, the key is to think about his interests, pay attention to his style, consider his needs, and get creative. Happy gifting!
    
    `,
    url: "/blog/bestgiftsforhim",
  },
  {
    blogName: "bestgiftsforher",
    imageSrc: Hers,
    category: "Gifting Tips",
    date: "April 21, 2022",
    title: "Best Gift For Her",
    description: "Give the best gift for your dad for valentines day..",
    p1: `Finding the perfect gift for the special women in your life can be challenging, especially when there are so many options to choose from. Whether you're looking for a gift for your girlfriend, female friend, or wife, this guide will help you choose the best gifts for her that she will cherish and appreciate.`,

    p2: `Consider her interests: The first step to finding the best gift for her is to consider her interests. Does she love reading, cooking, traveling, or working out? By considering her interests, you can narrow down the options and find something that she will truly enjoy.
    Think about the occasion: The occasion for the gift can also help you determine what to get her. Is it her birthday, anniversary, or Valentine's Day? A personalized gift would be perfect for these occasions, as it shows that you put in extra thought and effort into the gift.
    Pay attention to her hints: Pay attention to the little hints that she drops about what she wants. If she mentions a specific book, movie, or piece of jewelry that she wants, make a mental note of it and surprise her with it later.
    Go for quality over quantity: When it comes to buying gifts for her, it's better to go for quality over quantity. A well-made piece of jewelry, a luxurious spa day, or a high-quality handbag will be appreciated much more than a bunch of cheap trinkets.
    Now that you know how to choose the best gifts for her, here are 30 gift ideas that she is sure to love:`,

    items: [
      `Jewelry - A piece of jewelry is a classic gift for her that she will always cherish.`,
      `Personalized photo album - Fill a photo album with pictures of the two of you and add personalized captions to make it even more special.`,
      `Spa day - Treat her to a luxurious spa day where she can relax and unwind.`,
      `Designer handbag - Surprise her with a designer handbag that she's been eyeing for months.`,
      `Aromatherapy diffuser - Help her create a relaxing atmosphere at home with an aromatherapy diffuser.`,
      `Smartwatch - A smartwatch is a great gift for the tech-savvy woman who likes to stay connected.`,
      `Fitness tracker - Help her achieve her fitness goals with a fitness tracker.`,
      `Kindle - If she loves to read, a Kindle is the perfect gift for her.`,
      `Leather journal - A leather journal is a great gift for the woman who loves to write.`,
      `Cookbook - If she loves to cook, a cookbook by her favorite chef is a thoughtful gift.`,
      `Wine glasses - Give her a set of elegant wine glasses that she can use for special occasions.`,
      `Art print - A beautiful art print for her home or office is a great gift for the woman who loves art.`,
      `Wireless headphones - Help her tune out the world and listen to her favorite music with wireless headphones.`,
      `Cashmere sweater - Spoil her with a luxurious cashmere sweater that she can wear for years to come.`,
      `Designer sunglasses - Give her a pair of designer sunglasses that she can wear all year round.`,
      `Handmade soap - Handmade soap is a thoughtful and practical gift for her.`,
      `Yoga mat -  If she loves yoga, a high-quality yoga mat is a great gift.`,
      `Personalized necklace - A personalized necklace with her name or initials is a sweet and thoughtful gift.`,
      `Tea sampler -  If she loves tea, a sampler of different tea blends is a great gift idea.`,
      `Gourmet chocolates -  Indulge her sweet tooth with a box of gourmet chocolates.`,
      `Leather wallet -  A high-quality leather wallet is a practical gift that she will use every day.`,
      `Silk scarf - A silk scarf is a luxurious accessory that she can wear in a variety of ways.`,
      `Fitness equipment - If she's into fitness, consider getting her a piece of equipment for her home gym, like a set of weights or resistance bands.`,
      `Electric toothbrush -  Help her take care of her dental health with a high-tech electric toothbrush.`,
      `Portable speaker - A portable speaker is a great gift for the music lover who likes to take their tunes on the go.`,
      `Cookbook stand - If she loves to cook, a cookbook stand is a practical gift that will make her life easier in the kitchen.`,
      `Personalized wine stopper - A personalized wine stopper is a great gift for the wine lover who enjoys entertaining.`,
      `Cashmere scarf - A cashmere scarf is another luxurious accessory that she can wear in a variety of ways.`,
      `Women's leather jacket - A high quality leather jacket is a timeless gift that she will wear for years to come.`,
      `Air fryer - Help her create healthy and delicious meals with an air fryer.`,
    ],

    p3: `In conclusion, choosing the best gift for her doesn't have to be difficult. By considering her interests, paying attention to hints, and going for quality over quantity, you can find a gift that she will love and appreciate. Whether it's jewelry, a spa day, or a high-tech gadget, the options are endless. Use this guide and the 30 gift ideas above to find the perfect gift for the special women in your life.
    
    `,
    url: "/blog/bestgiftsforher",
  },
];

// const getPlaceholderPost = () => ({
//   imageSrc:
//     "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
//   category: "Gift Options",
//   date: "April 19, 2022",
//   title: "Get a trip as a gift!",
//   description: "Traveling Abroad might be the best gift.",
//   content: "Give the best gift for your dad for fathers day..",
//   url: "/blog",
// });

export default posts;
